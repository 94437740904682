<template>
<section class="page_title  pdt_80  pdb_40">
    <div class="container">
        <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12 text-center">
            <!---------col----------->
            <!---------col-end---------->
            </div>
            <!----------row-end---------->
        </div>
    </div>
</section>
<section class="blog_detail no_sidebar  pdb_100">
      <div class="container">
            <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12">
            <!---------col----------->
            <div class="featured_image">
                <div class="image_box">
                    <img src="@/assets/image/news/nitalauch.jpg" style="width:100%; height:420px !important; object-fit:cover; object-position:top" class="img-fluid" alt="img" />
                </div>
            </div>
            <!---------col-end---------->
            </div>
            <!----------row-end---------->
        </div>


        <div class="row">
            <!---------row----------->
            <div class="col-lg-2 col-md-12">
            <!---------enpty_column----------->
            </div>
            <div class="col-lg-8 col-md-12">
            <!---------col----------->
            <div class="blog_detail_content">
                <!-----blog_upper_box------->
                <div class="blog_detail_upper_box">
                    <h1>MTN, NITA-U LAUNCH NEW APP FOR TRACKING COVID-19 PATIENTS UNDER HOME BASED CARE</h1>
                    <ul class="bread_crumb text-center">`
                        <li class="bread_crumb-item"><a href="#">   <i class="linearicons-home4 home_icon"></i> </a></li>
                        <li class="bread_crumb-item"><a href="#">Media</a></li>
                        <li class="bread_crumb-item"><a href="#">News</a></li>
                        <li class="bread_crumb-item active">MTN, NITA-U LAUNCH NEW APP FOR TRACKING COVID-19</li>
                    </ul>
                    <ul class="blog_details_elements">
                        <li><small class="linearicons-calendar-full"></small> 20 Mar 2021</li>
                        <li><small class="linearicons-user"></small> Author: <a href="#">Mulengera</a></li>
                        <li><small class="linearicons-clipboard-empty"></small> Category: <a href="#">Technology</a></li>
                    </ul>
                    <h6>MTN Uganda in partnership with the National Information Technology Authority NITA-Uganda (NITA-U) on Friday March 19, 2021 unveiled a new app that will be used for geo-fencing and tracking COVID-19 patients that are under home based care. </h6>
                </div>
                <!-----blog_upper_box-end------>
                <!-----blog_lower_box------->
                <div class="blog_detail_lower_box">
                    
                    <p><span class="big_font mr-2"> T </span><span class="bg_font"> T </span> he new app named E-pass (Electronic pass) is intended to help the Ministry of Health truck and geo-fence the movement of COVID-19 patients under home based care, thus reducing the number of non-critical patients that get admitted in hospitals.</P>
                    <p>At the height of the pandemic, many people would be rushed to health centers, even those that were not in critical conditions, a situation that overwhelmed health centers and also stretched the limited resources in hospitals.
                    </p>
                    <div class="text_box">
                    <p>This situation led the Ministry of Health to develop a home-based care policy in which non-critical patients would be cared for and monitored from their homes or other locations outside the hospitals.</p>

                    <p>Speaking at the launch of the app, MTN Business General Manager, Mr. Ibrahim Ssenyonga, said the new app will go along away in helping government and the country to better manage resources as non-critical patients can now be monitored remotely from their locations of isolation.</p>

                    <p>“This new app will alert the Ministry of Health designated officials, if in case a patient under surveillance goes outside of the planned location boundaries. That way, the Ministry can minimize further spread, but also be able to locate some of the contacts in the areas where the patient might have veered off to,” said Mr. Ssenyonga.</p>

                    <p>Development of the app which lasted about four months, cost Shs460m with package including 400 smart phone handsets, which will have been installed with the app and will be used for monitoring.</p>

                    <p>At the height of the pandemic in Uganda, many Ugandans would storm health centers either in critical condition or out of fear resulting from normal flu or cold and they would all demand to be admitted.</p>

                    <p>NITA-U Executive Director, Dr. Hatwib Mugasa, said they are pleased with the solution as it will serve as a game changer in the management of COVID-19 pandemic.</p>

                    <p>“We are very proud because of the local capacity we have to quickly develop an app of this kind that will change the way we manage the COVID-19 pandemic in the country. This coming at a time when the vaccine is already in the country is a big boost in government efforts to curb and minimize the spread of the pandemic,” said Dr. Mugasa.</p>

                    <p>The Ministry of Health Permanent Secretary, Dr. Diana Atwine, thanked MTN and NITA-U for the intervention, saying that it takes collective effort from everyone to be able to defeat this disease.</p>

                    <p>“It is true that management of patients was quite a nightmare for government and the health centers. We were having many people coming to hospitals that didn’t need to be there. And these were taking space for those that needed to be there the most. So this solution is very welcome and it is a win for us as a country in the management of this pandemic,” said Dr. Atwine.</p>

                    <p>Dr. Atwine also paid special tribute to MTN who she said have really stood through thick and thin with the Ministry not only in the management of the COVID pandemic but in many other health related initiatives over the years.</p>

                    <p>MTN has been at the forefront of supporting government in the fight against COVID 19, contributing over Shs2bn in various interventions through the year 2020.</p>
                     
                    </div>
                    
                   
                 
                </div>
                <div class="more_content">
                    <div class="row">
                        <div class="col-lg-8">
                      
                        </div>
                        <div class="col-lg-4">
                        <ul class="media">
                            <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                            <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                        </ul>
                        </div>
                    </div>
                </div>
               
            </div>
            <!---------col-end---------->
            </div>
            <div class="col-lg-2 col-md-12">
            <!---------enpty_column----------->
            </div>
            <!----------row-end---------->
        </div>
      </div>
</section>
</template>